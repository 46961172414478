<template>
  <div class="offcanvas offcanvas-end" id="cartmini">
    <div class="cartmini__wrapper">
      <div class="cartmini__title">
        <h4>購物車內容</h4>
      </div>
      <div class="cartmini__close">
        <button type="button" class="cartmini__close-btn" data-bs-dismiss="offcanvas" aria-label="Close"><i class="fal fa-times"></i></button>
      </div>
      <div class="cartmini__widget">
        <div class="cartmini__inner">
          <ul>
            <li v-for="(specification, index) in items.specification" :key="`item${index}`">
              <div class="cartmini__thumb">
                <a :href="`/items/${specification.item.key}`">
                  <img :src="specification.item.image.pic.url">
                </a>
              </div>
              <div class="cartmini__content">
                <h5><a :href="`/items/${specification.item.key}`">{{specification.item.title}}</a></h5>
                <div class="mt-10">
                  <div>規格: {{specification.title}}</div>
                  <div>
                    <span>數量: {{cart.specification && cart.specification[specification.key]}} X </span>
                    <span>單價: ${{specification.price}}</span>
                    <span>= ${{cart.specification && cart.specification[specification.key] * specification.price}}</span>
                  </div>
                </div>
              </div>
              <a href="#!" class="cartmini__del" @click.prevent="remove(specification.key)"><i class="fal fa-times"></i></a>
            </li>
          </ul>
        </div>
        <div class="cartmini__checkout">
          <div class="cartmini__checkout-title mb-30">
            <h4>商品金額小計:</h4>
            <span>${{totalAmount.toLocaleString()}}</span>
          </div>
          <div class="cartmini__checkout-btn">
            <a href="#!" @click.prevent="goToCart" class="bd-fill__btn w-100"> <span></span> 結&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;賬</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import swal from 'sweetalert';
export default {
  props: ["itemsInCart"],
  data() {
    return {
      items: this.itemsInCart,
    }
  },
  computed: {
    cart() {
      return this.$store.state.cart;
    },
    totalAmount() {
      let total = 0;
      this.items.specification.forEach(specification => {
        if (this.cart.specification) {
          total += specification.price * this.cart.specification[specification.key];
        }
      });
      return total;
    },
  },
  methods: {
    remove(specificationKey) {
      let tempCart = {...this.cart.specification};
      delete tempCart[specificationKey];
      this.$store.dispatch('setCart', tempCart);
      this.items.specification = this.items.specification.filter(spec => spec.key !== specificationKey);
    },
    goToCart() {
      if (this.cart && Object.keys(this.cart.specification).length > 0) {
        window.location.href = '/orders/new';
      } else {
        swal("無法結賬！購物車內沒有商品");
      }
    }
  },
  watch: {
    cart() {
      axios.post('/carts/get_items').then(({data}) => {
        if (data.status === 'success') {
          this.items = data.items_in_cart;
        }
      });
    },
  },
}
</script>