<template>
  <div class="vue-form">
    <!-- tabs -->
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a :class="`nav-link ${currentTab == 'step1' ? 'active' : ''}`" href="#!"
          >Step1. 報名資料</a
        >
      </li>
      <li class="nav-item">
        <a :class="`nav-link ${currentTab == 'step2' ? 'active' : 'disabled'}`" href="#!"
          >Step2. 付費資料</a
        >
      </li>
    </ul>
    <!-- 內容 -->
    <div class="tab-content mt-20">
      <!-- 報名資料 -->
      <div id="step1" :class="`tab-pane fade ${currentTab == 'step1' ? 'active show' : ''}`">
        <ValidationObserver class="validation posr" v-slot="{ handleSubmit }">
          <form id="step1-form" class="system-form" @submit.prevent="handleSubmit(goToStep2)">
            <!-- 姓名 -->
            <div class="row event-form-group">
              <div class="col-lg-2 col-md-3 col-4">
                <label>報名人姓名</label>
              </div>
              <div class="col-lg-10 col-md-9 col-8">
                <ValidationProvider
                  tag="div"
                  class="posr-form"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input type="text" v-model="step1Form.payer_name" />
                  <div class="error">{{ errors[0] }}</div>
                </ValidationProvider>
              </div>
            </div>
            <!-- 聯絡電話 -->
            <div class="row event-form-group">
              <div class="col-lg-2 col-md-3 col-4">
                <label>聯絡電話</label>
              </div>
              <div class="col-lg-10 col-md-9 col-8">
                <input type="text" v-model="step1Form.payer_tel" />
              </div>
            </div>
            <!-- settings -->
            <div
              v-for="(setting, index) in eventOrderSettings"
              :key="`setting${index}`"
              class="row event-form-group"
            >
              <div class="col-lg-2 col-md-3 col-4">
                <label>{{ setting.title }}</label>
              </div>
              <div class="col-lg-10 col-md-9 col-8">
                <ValidationProvider
                  tag="div"
                  class="posr-form"
                  :rules="{
                    required: true,
                    regex: setting.rule == 'regex' ? new RegExp(setting.pattern) : false,
                  }"
                  v-slot="{ errors }"
                >
                  <input
                    v-if="setting.rule === 'string'"
                    type="text"
                    v-model="step1Form[setting.key]"
                    :placeholder="setting.hint"
                  />
                  <input
                    v-if="setting.rule === 'number'"
                    type="number"
                    v-model="step1Form[setting.key]"
                    :placeholder="setting.hint"
                  />
                  <input
                    v-if="setting.rule === 'email'"
                    type="email"
                    v-model="step1Form[setting.key]"
                    :placeholder="setting.hint"
                  />
                  <date-picker
                    v-if="setting.rule === 'date'"
                    v-model="step1Form[setting.key]"
                    :placeholder="setting.hint"
                    lang="zh-tw"
                    :default-value="new Date('1980-01-01')"
                  ></date-picker>
                  <input
                    v-if="setting.rule === 'regex'"
                    type="text"
                    v-model="step1Form[setting.key]"
                    :placeholder="setting.hint"
                  />
                  <select v-model="step1Form[setting.key]" v-if="Array.isArray(setting.rule)">
                    <option
                      v-for="(option, jndex) in setting.rule"
                      :value="option"
                      :key="`${setting.key}_option_${jndex}`"
                    >
                      {{ option }}
                    </option>
                  </select>
                  <div class="error">{{ errors[0] }}</div>
                </ValidationProvider>
              </div>
            </div>
            <!-- 備註 -->
            <div class="row event-form-group">
              <div class="col-lg-2 col-md-3 col-4">
                <label>報名備註</label>
              </div>
              <div class="col-lg-10 col-md-9 col-8">
                <input type="text" v-model="step1Form.comment" />
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <!-- 報名資料 -->
      <div id="step2" :class="`tab-pane fade ${currentTab == 'step2' ? 'active show' : ''}`">
        <ValidationObserver class="validation posr" v-slot="{ handleSubmit }">
          <form id="step2-form" class="system-form" @submit.prevent="handleSubmit(onSubmit)">
            <!-- 活動費用 -->
            <div class="row event-form-group">
              <div class="col-lg-2 col-md-3 col-4">
                <label>活動/課程金額:</label>
              </div>
              <div class="col-lg-10 col-md-9 col-8">
                <p class="event-p">{{ event.price.toLocaleString() }} 元</p>
              </div>
            </div>
            <!-- 折價劵號 判斷coupon能否使用  -->
            <div v-if="event.coupon_usable === 'yes_usable'" class="row event-form-group">
              <div class="col-lg-2 col-md-3 col-4">
                <label>使用折價劵:</label>
              </div>
              <div class="col-lg-10 col-md-9 col-8">
                <input
                  type="text"
                  v-model="coupon"
                  placeholder="請在此輸入折價劵號..."
                  @change.prevent="checkCoupon"
                />
              </div>
            </div>
            <!-- 應付金額 -->
            <div v-if="coupon_discount > 0" class="row event-form-group">
              <div class="col-lg-2 col-md-3 col-4">
                <label>應付金額:</label>
              </div>
              <div class="col-lg-10 col-md-9 col-8">
                <p class="event-p">
                  {{ total.toLocaleString() }} 元 (折價劵折抵{{ coupon_discount }}元)
                </p>
              </div>
            </div>
            <!-- 付款方式 -->
            <div class="row event-form-group">
              <div class="col-lg-2 col-md-3 col-4">
                <label>付款方式:</label>
              </div>
              <div class="col-lg-10 col-md-9 col-8">
                <label class="label-inline" v-for="(type, index) in payTypes" :key="`type${index}`">
                  <input type="radio" :value="type[1]" v-model="pay_type" />
                  {{ type[0] }}
                </label>
              </div>
            </div>
            <!-- 發票資訊 -->
            <div class="row event-form-group">
              <div class="col-lg-2 col-md-3 col-4">
                <label>發票種類:</label>
              </div>
              <div class="col-lg-10 col-md-9 col-8">
                <select v-model="invoice_type">
                  <option
                    v-for="(type, index) in invoiceTypes"
                    :key="`invoiceType${index}`"
                    :value="type[1]"
                  >
                    {{ type[0] }}
                  </option>
                </select>
              </div>
            </div>
            <!-- 發票抬頭 -->
            <div v-if="invoice_type == 'three'" class="row event-form-group">
              <div class="col-lg-2 col-md-3 col-4">
                <label>發票抬頭:</label>
              </div>
              <div class="col-lg-10 col-md-9 col-8">
                <ValidationProvider
                  tag="div"
                  class="posr-form"
                  :rules="{ required: invoice_type == 'three' }"
                  v-slot="{ errors }"
                >
                  <input type="text" v-model="invoice_title" placeholder="請輸入公司行號抬頭..." />
                  <div class="error">{{ errors[0] }}</div>
                </ValidationProvider>
              </div>
            </div>
            <!-- 統一編號 -->
            <div v-if="invoice_type == 'three'" class="row event-form-group">
              <div class="col-lg-2 col-md-3 col-4">
                <label>統一編號:</label>
              </div>
              <div class="col-lg-10 col-md-9 col-8">
                <ValidationProvider
                  tag="div"
                  class="posr-form"
                  :rules="{ required: invoice_type == 'three', regex: /\d{8}/ }"
                  v-slot="{ errors }"
                >
                  <input type="text" v-model="vat_code" placeholder="請輸入公司統一編號..." />
                  <div class="error">{{ errors[0] }}</div>
                </ValidationProvider>
              </div>
            </div>
            <!-- 收件地址 -->
            <div
              v-if="
                (invoice_type == 'three' || einvoice_carruer_type == 'print') &&
                invoice_type != 'donate'
              "
              class="row event-form-group"
            >
              <div class="col-lg-2 col-md-3 col-4">
                <label>收件地址:</label>
              </div>
              <div class="col-lg-10 col-md-9 col-8">
                <ValidationProvider
                  tag="div"
                  class="posr-form"
                  :rules="{ required: invoice_type == 'three' || einvoice_carruer_type == 'print' }"
                  v-slot="{ errors }"
                >
                  <input
                    type="text"
                    v-model="einvoice_address"
                    placeholder="請輸入發票收件地址..."
                  />
                  <div class="error">{{ errors[0] }}</div>
                </ValidationProvider>
              </div>
            </div>
            <!-- 載具形式 -->
            <div v-if="invoice_type == 'two'" class="row event-form-group">
              <div class="col-lg-2 col-md-3 col-4">
                <label>載具形式:</label>
              </div>
              <div class="col-lg-10 col-md-9 col-8">
                <select v-model="einvoice_carruer_type">
                  <option
                    v-for="(type, index) in einvoiceCarruerTypes"
                    :key="`einvoiceCarruerType${index}`"
                    :value="type[1]"
                  >
                    {{ type[0] }}
                  </option>
                </select>
              </div>
            </div>
            <!-- 載具編號 -->
            <div
              v-if="
                invoice_type == 'two' &&
                (einvoice_carruer_type == 'natural_man' || einvoice_carruer_type == 'cell_carruer')
              "
              class="row event-form-group"
            >
              <div class="col-lg-2 col-md-3 col-4">
                <label>載具編號:</label>
              </div>
              <div class="col-lg-10 col-md-9 col-8">
                <ValidationProvider
                  tag="div"
                  class="posr-form"
                  :rules="{
                    required:
                      einvoice_carruer_type == 'natural_man' ||
                      einvoice_carruer_type == 'cell_carruer',
                    regex: einvoice_carruer_type == 'natural_man' ? /^[A-Z]{2}\d{14}$/ : false,
                    checkBarcode: einvoice_carruer_type == 'cell_carruer',
                  }"
                  v-slot="{ errors }"
                >
                  <input
                    type="text"
                    v-model="einvoice_carruer_num"
                    placeholder="依載具形式輸入編號..."
                  />
                  <div class="error">{{ errors[0] }}</div>
                </ValidationProvider>
              </div>
            </div>
            <!-- 載具Email -->
            <div
              v-if="
                invoice_type != 'donate' &&
                (einvoice_carruer_type == 'print' || invoice_type == 'three')
              "
              class="row event-form-group"
            >
              <div class="col-lg-2 col-md-3 col-4">
                <label>紙本接收Email:</label>
              </div>
              <div class="col-lg-10 col-md-9 col-8">
                <ValidationProvider
                  tag="div"
                  class="posr-form"
                  :rules="{
                    required: einvoice_carruer_type == 'print' || invoice_type == 'three',
                    email: true,
                  }"
                  v-slot="{ errors }"
                >
                  <input
                    type="text"
                    v-model="einvoice_carruer_email"
                    placeholder="請輸入發票收件Email..."
                  />
                  <div class="error">{{ errors[0] }}</div>
                </ValidationProvider>
              </div>
            </div>
            <!-- 愛心碼 -->
            <div v-if="invoice_type == 'donate'" class="row event-form-group">
              <div class="col-lg-2 col-md-3 col-4">
                <label>捐贈單位:</label>
              </div>
              <div class="col-lg-10 col-md-9 col-8">
                <select v-model="love_code">
                  <option
                    v-for="(opt, index) in loveCodeOptions"
                    :key="`loveCodeOption${index}`"
                    :value="opt[1]"
                  >
                    {{ opt[0] }}
                  </option>
                </select>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <div class="text-center">
        <!-- 上一步 下一步-->
        <button v-if="currentTab == 'step1'" type="submit" form="step1-form" class="bd-fill__btn">
          下一步
        </button>
        <button
          v-if="currentTab == 'step2'"
          type="button"
          class="bd-border__btn"
          @click.prevent="currentTab = 'step1'"
        >
          上一步
        </button>
        <button
          v-if="currentTab == 'step2'"
          type="submit"
          form="step2-form"
          class="bd-fill__btn"
          :disabled="submitting"
        >
          {{ submitting ? "處理中..." : "進行付款" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { extend } from "vee-validate";
import { required, regex, email } from "vee-validate/dist/rules";
import swal from "sweetalert";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/zh-tw";

extend("required", {
  ...required,
  message: "必填欄位！",
});

extend("regex", {
  ...regex,
  message: "欄位格式錯誤!",
});

extend("email", {
  ...email,
  message: "請輸入Email格式!",
});

const checkBarcode = (value) => {
  if (value.length == 8) {
    return axios.post("/orders/check_mobile_barcode", { barcode: value }).then(({ data }) => {
      return {
        valid: data,
        data: {
          message: "條碼不存在!",
        },
      };
    });
  } else {
    return {
      valid: false,
      data: {
        message: "長度不足",
      },
    };
  }
};

extend("checkBarcode", {
  validate: checkBarcode,
  message: "條碼不存在！",
});

export default {
  components: {
    DatePicker,
  },
  props: [
    "eventOrderSettings",
    "user",
    "payTypes",
    "event",
    "invoiceTypes",
    "einvoiceCarruerTypes",
    "loveCodeOptions",
  ],
  data() {
    return {
      currentTab: "step1",
      step1Form: {
        payer_name: this.user.name,
        payer_tel: this.user.tel,
      },
      coupon: "",
      coupon_discount: 0,
      pay_type: this.payTypes[0][1],
      invoice_type: this.invoiceTypes[0][1],
      invoice_title: "",
      vat_code: "",
      einvoice_carruer_type: this.einvoiceCarruerTypes[0][1],
      einvoice_carruer_num: "",
      einvoice_carruer_email: this.user.show_email || "",
      love_code: this.loveCodeOptions[0][1],
      einvoice_address: "",
      submitting: false,
    };
  },
  computed: {
    total() {
      return this.event.price - this.coupon_discount;
    },
  },
  methods: {
    goToStep2() {
      this.currentTab = "step2";
    },
    checkCoupon() {
      if (this.coupon.length >= 5) {
        axios
          .post("/event_orders/coupon_check", {
            coupon: this.coupon,
            event: this.event.key,
          })
          .then(({ data }) => {
            if (data.status == "success") {
              this.coupon_discount = data.coupon_discount;
              swal("折價劵驗證成功", "折價劵折扣 " + data.coupon_discount + " 元", "success");
            } else {
              swal("折價劵驗證失敗", data.message, "error");
            }
          });
      }
    },
    onSubmit() {
      this.submitting = true;
      // console.log('submitting')
      const form = document.createElement("form");
      const csrf = $("meta[name=csrf-token]").attr("content");
      form.method = "POST";
      form.action = "/event_orders";
      form.style.display = "none";
      // csrf
      const csrfToken = document.createElement("input");
      csrfToken.name = "authenticity_token";
      csrfToken.value = csrf;
      form.appendChild(csrfToken);
      // step1 form
      const { step1Form } = this;
      Object.entries(step1Form).forEach((field) => {
        const input = document.createElement("input");
        input.name = `event_order[${field[0]}]`;
        input.value = field[1];
        form.appendChild(input);
      });
      // step2 form
      const couponInput = document.createElement("input");
      couponInput.name = "coupon";
      couponInput.value = this.coupon;
      form.appendChild(couponInput);
      const payTypeInput = document.createElement("input");
      payTypeInput.name = "event_order[pay_type]";
      payTypeInput.value = this.pay_type;
      form.appendChild(payTypeInput);
      const invoiceTypeInput = document.createElement("input");
      invoiceTypeInput.name = "event_order[invoice_type]";
      invoiceTypeInput.value = this.invoice_type;
      form.appendChild(invoiceTypeInput);
      const invoiceTitleInput = document.createElement("input");
      invoiceTitleInput.name = "event_order[invoice_title]";
      invoiceTitleInput.value = this.invoice_title;
      form.appendChild(invoiceTitleInput);
      const vatCodeInput = document.createElement("input");
      vatCodeInput.name = "event_order[vat_code]";
      vatCodeInput.value = this.vat_code;
      form.appendChild(vatCodeInput);
      const einvoiceAddressInput = document.createElement("input");
      einvoiceAddressInput.name = "event_order[einvoice_address]";
      einvoiceAddressInput.value = this.einvoice_address;
      form.appendChild(einvoiceAddressInput);
      const einvoiceCarruerTypeInput = document.createElement("input");
      einvoiceCarruerTypeInput.name = "event_order[einvoice_carruer_type]";
      einvoiceCarruerTypeInput.value = this.einvoice_carruer_type;
      form.appendChild(einvoiceCarruerTypeInput);
      const einvoiceCarruerNumInput = document.createElement("input");
      einvoiceCarruerNumInput.name = "event_order[einvoice_carruer_num]";
      einvoiceCarruerNumInput.value = this.einvoice_carruer_num;
      form.appendChild(einvoiceCarruerNumInput);
      const einvoiceCarruerEmailInput = document.createElement("input");
      einvoiceCarruerEmailInput.name = "event_order[einvoice_carruer_email]";
      einvoiceCarruerEmailInput.value = this.einvoice_carruer_email;
      form.appendChild(einvoiceCarruerEmailInput);
      const loveCodeInput = document.createElement("input");
      loveCodeInput.name = "event_order[einvoice_love_code]";
      loveCodeInput.value = this.love_code;
      form.appendChild(loveCodeInput);
      // event key
      const eventInput = document.createElement("input");
      eventInput.name = "event";
      eventInput.value = this.event.key;
      form.appendChild(eventInput);
      // 加到document
      document.body.appendChild(form);
      form.submit();
    },
  },
  mounted() {
    this.eventOrderSettings.forEach((setting) => {
      this.step1Form[setting.key] = setting.default;
    });
  },
};
</script>
