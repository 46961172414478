<template>
  <div class="d-flex flex-column w-100">
    <div v-if="specifications.length > 0" class="flex1">
      <select v-model="selectedSpecification" class="add-to-cart-select w-100" @change.prevent="selectSpecification">
        <option v-for="spec in specifications" :key="spec.key" :value="spec.key">{{`${spec.title} $${spec.price}`}}</option>
      </select>
    </div>
    <div v-if="qty >= 1" class="single-item-vue-container flex1">
      <div class="product-quantity-form">
        <button class="cart-minus" @click.prevent="minus"><i class="far fa-minus"></i></button>
        <input class="cart-input" type="text" v-model="qty">
        <button class="cart-plus" @click.prevent="add"><i class="far fa-plus"></i></button>
      </div>
      <button class="cart-btn bd-fill__btn" data-toggle="tooltip" data-placement="top" @click.prevent="addToCart">
        <i class="fal fa-cart-arrow-down"></i>購 買
      </button>
    </div>
    <!-- 無庫存 -->
    <div v-if="!qty" class="single-item-vue-container flex1">
      <h3>無庫存</h3>
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert';
export default {
  props: ["specifications", "item"],
  data() {
    return {
      selectedSpecification: this.specifications.length > 0 ? this.specifications[0].key : null,
      qty: this.specifications[0]?.qty > 0 ? 1 : null,
    }
  },
  computed: {
    cart() {
      return this.$store.state.cart;
    }
  },
  methods: {
    minus() {
      if (this.qty > 1) {
        this.qty -= 1
      } else {
        this.qty = 1;
        swal({
          title: "數量不能小於0",
          icon: "warning",
          button: "確定",
        });
      }
    },
    add() {
      if (this.qty === null) {
        swal({
          title: "此規格已售完！",
          text: "",
          icon: "warning"
        })
      } else {
        if (this.specifications.find((spec) => spec.key == this.selectedSpecification).max_qty > this.qty) {
          this.qty += 1
        } else {
          swal({
            title: "已達購買上限",
            icon: "warning"
          })
        }
      }
    },
    addToCart() {
      if (this.qty === null) {
        swal({
          title: "此規格已售完！",
          icon: "warning"
        })
      } else {
        const currentSpecification = this.specifications.find(specification => specification.key === this.selectedSpecification);
        if (this.qty > currentSpecification.max_qty) {
          swal({
            title: "數量選擇超過庫存上限",
            icon: "warning"
          });
        } else {
          this.$store.dispatch('setCart', {...this.cart.specification, [this.selectedSpecification]: this.qty})
          swal({
            title: "已加入購物車",
            icon: "success"
          });
        }
      }
    },
    selectSpecification() {
      const currentSpecification = this.specifications.find(specification => specification.key === this.selectedSpecification);
      // console.log(currentSpecification, this.selectedSpecification)
      // 判斷qty
      if (currentSpecification.qty > 0) {
        // 判斷是否已在購物車
        if (this.cart.specification[this.selectedSpecification]) {
          this.qty = this.cart.specification[this.selectedSpecification];
        } else {
          this.qty = 1;
        }
      } else {
        this.qty = null;
      }
    },
  }
}
</script>