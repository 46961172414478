<template>
  <table class="table">
    <thead>
      <tr>
        <th>功能</th>
        <th>圖片</th>
        <th>商品</th>
        <th>規格</th>
        <th>單價</th>
        <th>數量</th>
        <th>小計</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(specification, index) in items.specification" :key="`item${index}`">
        <td>
          <a href="#!" @click.prevent="remove(specification.key)"><i class="fal fa-times"></i></a>
        </td>
        <td>
          <a :href="`/items/${specification.item.key}`">
            <img :src="specification.item.image.pic.url" class="img-small" />
          </a>
        </td>
        <td>
          <a :href="`/items/${specification.item.key}`">
            {{ specification.item.title }}
          </a>
        </td>
        <td>
          {{ specification.title }}
        </td>
        <td>
          <span class="amount">${{ specification.price.toLocaleString() }}</span>
        </td>
        <td>
          <div class="product-quantity mt-10 mb-10">
            <div class="product-quantity-form">
              <button class="cart-minus" @click.prevent="minus(specification.key)">
                <i class="far fa-minus"></i>
              </button>
              <input
                class="cart-input"
                type="text"
                readonly
                :value="cart.specification && cart.specification[specification.key]"
              />
              <button class="cart-plus" @click.prevent="add(specification.key)">
                <i class="far fa-plus"></i>
              </button>
            </div>
          </div>
        </td>
        <td>
          <span class="amount"
            >${{
              cart.specification &&
              (specification.price * cart.specification[specification.key]).toLocaleString()
            }}</span
          >
        </td>
      </tr>
      <!-- 有桌機版與手機版 -->
      <!-- 小計 -->
      <tr>
        <td v-if="!isMobile" colspan="6" class="text-end">商品金額小計:</td>
        <td v-if="!isMobile">
          <span class="amount">${{ totalAmount.toLocaleString() }}</span>
        </td>
        <!-- 手機版 -->
        <td v-if="isMobile" colspan="7" class="text-start">
          商品金額小計: <span class="amount">${{ totalAmount.toLocaleString() }}</span>
        </td>
      </tr>
      <!-- coupon -->
      <tr v-if="couponUsable">
        <td v-if="!isMobile" colspan="6" class="text-end">
          輸入折價劵:
          <input
            class="input-text-align"
            :value="couponNo"
            @change.prevent="checkCoupon"
            @keyup.prevent="checkCoupon"
            type="text"
          />
        </td>
        <td v-if="!isMobile">
          <span class="amount" v-if="couponDiscount && couponDiscount != 0"
            >${{ couponDiscount }}</span
          >
          <span class="amount" v-if="!couponDiscount">無資料</span>
        </td>
        <!-- 手機版 -->
        <td v-if="isMobile" colspan="7" class="text-start">
          輸入折價劵:
          <input
            class="input-text-align"
            :value="couponNo"
            @change.prevent="checkCoupon"
            @keyup.prevent="checkCoupon"
            type="text"
          />
          <span class="amount" v-if="couponDiscount && couponDiscount != 0"
            >${{ couponDiscount }}</span
          >
          <span class="amount" v-if="!couponDiscount">無資料</span>
        </td>
      </tr>
      <!-- 運費 -->
      <tr>
        <td v-if="!isMobile" colspan="6" class="text-end">
          運費 <span v-show="checkHasFreight">(滿{{ freeShipmentCondition }}免運)</span>
          <!-- 有非免運則需要提示 -->
          <span v-show="!checkHasFreight">(商品不符合免運條件)</span>
          <!-- 手動選擇免運 -->
          <label
            ><input type="checkbox" v-model="freightFreeCheck" @change.prevent="changeFormValue" />
            我要自取 :</label
          >
        </td>
        <td v-if="!isMobile">
          <span class="amount">${{ freight }}</span>
        </td>
        <!-- 手機版 -->
        <td v-if="isMobile" colspan="7" class="text-start">
          運費 <span v-show="checkHasFreight">(滿{{ freeShipmentCondition }}免運)</span>
          <!-- 有非免運則需要提示 -->
          <span v-show="!checkHasFreight">(商品不符合免運條件)</span>
          <!-- 手動選擇免運 -->
          <label>
            <input type="checkbox" v-model="freightFreeCheck" @change.prevent="changeFormValue" />
            我要自取 :
          </label>
          <span class="amount">${{ freight }}</span>
        </td>
      </tr>
      <!-- 應付 -->
      <tr>
        <td v-if="!isMobile" colspan="6" class="text-end">應付金額:</td>
        <td v-if="!isMobile">
          <span class="amount">${{ calculateTotal && calculateTotal.toLocaleString() }}</span>
        </td>
        <!-- 手機版 -->
        <td v-if="isMobile" colspan="7" class="text-start">
          應付金額:
          <span class="amount">${{ calculateTotal && calculateTotal.toLocaleString() }}</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
export default {
  props: ["itemsInCart", "freeShipmentCondition", "isMobile"],
  data() {
    return {
      items: this.itemsInCart,
      freightFreeCheck: false,
    };
  },
  computed: {
    freight() {
      if (this.freightFreeCheck) {
        return 0;
      } else {
        return this.$store.state.freight;
      }
    },
    cart() {
      return this.$store.state.cart;
    },
    couponDiscount() {
      return this.$store.state.couponDiscount;
    },
    couponNo() {
      return this.$store.state.couponNo;
    },
    totalAmount() {
      let total = 0;
      this.items.specification.forEach((specification) => {
        if (this.cart.specification) {
          total += specification.price * this.cart.specification[specification.key];
        }
      });
      return total;
    },
    calculateTotal() {
      let total = 0;
      total = this.totalAmount - this.couponDiscount;
      if (total < 0) {
        total = 0;
      }
      // 自取免運
      if (this.freightFreeCheck) {
        return total;
      } else {
        return total + this.freight;
      }
    },
    couponUsable() {
      // 商品中有設定coupon_usable為yes_usable的話，才可以使用coupon
      let result = this.items.specification.some((spec) => {
        return spec.item.coupon_usable === "yes_usable";
      });
      console.log(result);
      return result;
    },
    checkHasFreight() {
      // 20230724 非免運商品，購物車中有任一個非免運(yes_freight)則全部商品都會有運費，除非是自取
      // 如果itemsInCart.specification中的item的has_freight有yes_freight，則不顯示免運
      let result = this.items.specification.some((spec) => {
        return spec.item.has_freight === "yes_freight";
      });
      return !result;
    },
  },
  methods: {
    add(specificationKey) {
      // 取得當前的數量
      let qty = this.cart.specification[specificationKey];
      let spec = this.items.specification.find((spec) => spec.key === specificationKey);
      qty += 1;
      if (qty > spec.max_qty) {
        swal({
          title: "超過上限",
          text: "您的選擇已超過庫存上限",
          icon: "warning",
        });
      } else {
        this.$store.dispatch("setCart", { ...this.cart.specification, [specificationKey]: qty });
      }
    },
    minus(specificationKey) {
      let qty = this.cart.specification[specificationKey];
      qty -= 1;
      if (qty <= 0) {
        swal({
          title: "低於下限",
          text: "數量請勿低於0。如要刪除此項商品，請點擊刪除鈕。",
          icon: "warning",
        });
      } else {
        this.$store.dispatch("setCart", { ...this.cart.specification, [specificationKey]: qty });
      }
    },
    remove(specificationKey) {
      let tempCart = { ...this.cart.specification };
      delete tempCart[specificationKey];
      this.$store.dispatch("setCart", tempCart);
      this.items.specification = this.items.specification.filter(
        (spec) => spec.key !== specificationKey
      );
      if (this.items.specification.length === 0) {
        swal({
          title: "您的購物車是空的",
          text: "請先選擇商品",
          icon: "warning",
        }).then(() => {
          window.location.href = "/items";
        });
      }
    },
    checkCoupon(e) {
      const value = e.target.value;
      if (value.length >= 5) {
        this.$store.dispatch("setCouponDiscount", value);
      }
    },
    changeFormValue() {
      // console.log(this.freightFreeCheck);
      if (this.freightFreeCheck) {
        $("#order_freight_free").val("yes_freight_free");
      } else {
        $("#order_freight_free").val("no_freight_free");
      }
    },
  },
  watch: {
    cart() {
      axios.post("/carts/get_items").then(({ data }) => {
        if (data.status === "success") {
          this.items = data.items_in_cart;
        }
      });
    },
  },
};
</script>
