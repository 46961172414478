<template>
  <div class="bd-action__item">
    <div class="bd-action__cart">
      <div class="bd-action__cart-icon">
        <a href="#!" data-bs-toggle="offcanvas" data-bs-target="#cartmini" aria-controls="cartmini">
          <i class="fas fa-shopping-cart"></i>
        </a>
        <span class="bd-action__item-number cart-count">{{qty}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    qty() {
      if (this.$store.state.cart?.specification) {
        return Object.keys(this.$store.state.cart.specification).length;
      } else {
        return 0;
      }
    }
  }
}
</script>