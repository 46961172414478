<template>
  <a class="cart-btn" href="#!" @click.prevent="addToCart">
    <i class="fal fa-cart-arrow-down"></i>
  </a>
</template>

<script>
import swal from "sweetalert";
export default {
  props: ["specifications", "item"],
  data() {
    return {
      selectedSpecification: this.specifications.length > 0 ? this.specifications[0].key : null,
      qty: this.specifications[0]?.qty > 0 ? 1 : null,
    };
  },
  computed: {
    cart() {
      return this.$store.state.cart;
    },
  },
  methods: {
    addToCart() {
      // console.log("clicked")
      if (this.qty === null) {
        swal({
          title: "此規格已售完！",
          icon: "warning",
        });
      } else {
        const currentSpecification = this.specifications.find(
          (specification) => specification.key === this.selectedSpecification
        );
        if (this.qty > currentSpecification.max_qty) {
          swal({
            title: "數量選擇超過庫存上限",
            icon: "warning",
          });
        } else {
          this.$store.dispatch("setCart", {
            ...this.cart.specification,
            [this.selectedSpecification]: this.qty,
          });
          swal({
            title: "已加入購物車",
            icon: "success",
          });
        }
      }
    },
  },
};
</script>
